import { SETTINGS } from '@/models/CreatesSurveyModel';
import configSite from '../../config/configSite';

const { ANSWER } = SETTINGS;
export default class constants {
  static MEMBER_TYPE_SPONSOR = 'sponsor';

  static MEMBER_TYPE_COLLECTOR = 'collector';
}

// Question type
export const QT_SINGLE_CHOICE = 'SINGLE_CHOICE';
export const QT_MULTIPLE_CHOICE = 'MULTIPLE_CHOICE';
export const QT_TEXT = 'TEXT';
export const QT_RATING = 'RATING';
export const QT_KEYWORD = 'KEYWORD';
export const QT_RANKING = 'RANKING';
export const QT_BRANCHING = 'BRANCHING_QUESTION';
export const QT_ASSESSMENT = 'ASSESSMENT';
export const QT_SLIDER = 'SLIDER';
export const QT_FEELING = 'FEELING';

// Answer type
export const AT_TEXT = 'TEXT';
export const AT_IMAGE = 'IMAGE';
export const IS_TRUE = true;
export const IS_URL = true;
export const COMPLETED = 'COMPLETED';
export const NOT_COMPLETE = 'NOT_COMPLETE';
// Chart Color
export const TEAL_BOLD = '#33B3AA';
export const TEAL = '#2AAEB7';
export const LIGHT_TEAL = '#A0D9D5';
export const YELLOW = '#FDD133';
export const LIGHT_YELLOW = '#FFE8A7';
export const LIGHT_ORANGE = '#FFA772';
export const RED = '#FF5A00';
export const SUB_TEXT = '#B3B3B3';
export const SUB_TEXT_1 = '#797A7A';
export const BORDER = '#ECECEC';
export const BLACK = '#000000';
export const WHITE = '#FFFFFF';
export const GRAY_6 = '#D9D9D9';
export const GRAY_8 = '#F5F6FA';
export const GRAY_1 = '#26313F';
export const PURPLE = '#8884D8';
export const BLUE = '#1890FF';
// Chart Color live
export const DARK_GREEN = '#33ABA2';
export const LIGHT_GREEN = '#A0D9D5';
export const ORANGE = '#FFC727';
export const LIGHT_ORANGE_LIVE = '#FFE8A7';
export const RED_LIVE = '#FF5A00';
export const LIGHT_PINK = '#FF9F67';
export const DARK_GRAY = '#797A7A';
export const LIGHT_GRAY = '#B3B3B3';
// Background
export const SUMMARY_BG = '#F2F2F2';
export const WHITE_COLOR = '#FFF';
// Font-family
export const OPEN_SANS = 'Open Sans';
export const GILROY = 'Gilroy';
// Reactions
export const ALL_EMOJI = 'ALL';
export const HAHA_EMOJI = 'HAHA';
export const LIKE_EMOJI = 'LIKE';
export const WOW_EMOJI = 'WOW';
export const CARE_EMOJI = 'CARE';
export const ANGRY_EMOJI = 'ANGRY';
export const SAD_EMOJI = 'SAD';
export const ALL_EMOJI_TITLE = 'All';
export const HAHA_EMOJI_TITLE = 'Haha';
export const LIKE_EMOJI_TITLE = 'Like';
export const WOW_EMOJI_TITLE = 'Wow';
export const CARE_EMOJI_TITLE = 'Care';
export const ANGRY_EMOJI_TITLE = 'Angry';
export const SAD_EMOJI_TITLE = 'Sad';
// Reaction COlor
export const HAHA_EMOJI_COLOR = 'rgb(247, 177, 37)';
export const LIKE_EMOJI_COLOR = 'rgb(32, 120, 244)';
export const WOW_EMOJI_COLOR = 'rgb(247, 177, 37)';
export const CARE_EMOJI_COLOR = 'rgb(247, 177, 37)';
export const ANGRY_EMOJI_COLOR = 'rgb(233, 113, 15)';
export const SAD_EMOJI_COLOR = 'rgb(247, 177, 37)';
// Status
export const STATUS_DRAFT = 'DRAFT';
export const STATUS_WAITING = 'WAITING';
export const STATUS_RUNNING = 'RUNNING';
export const STATUS_CLOSED = 'CLOSED';
export const STATUS_USED = 'USED';
export const STATUS_NOT_USED = 'NOT_USED';
// Regex
export const regexImageUrl = new RegExp('(http(s?):)([/|.|w|s|-])*.(?:jpg|gif|png)*');

export const FEELING = {
  SAD: 0,
  NOTGOOD: 25,
  OK: 50,
  GOOD: 75,
  GREAT: 100,
};

export const FEELING_TEXT = {
  BAD: 'Bad',
  NOTGOOD: 'Not Good',
  OK: "It's OK",
  GOOD: 'Good',
  GREAT: 'Great',
};

export const ResourceType = {
  COLLECTORS: 'collectors',
  SPONSORS: 'sponsors',
  QUESTIONS: 'questions',
  PULSES: 'pulses',
};

export const ORDER = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const ORDER_BY = {
  ID: 'id',
  CREATED_AT: 'createdAt',
  UPDATED_AT: 'updatedAt',
};

export const QUESTION_TYPE = {
  TEXT: 0,
  SINGLE: 1,
  MULTIPLE: 2,
  FEELING: 3,
  RATING: 4,
  RANKING: 5,
  SLIDER: 6,
  SORTING: 7,
  ASSESS: 8,
  UPLOAD: 9,
  KEYWORD: 10,
  BRANCHING: 11,
};

export const ANSWER_TYPE = {
  TEXT: 0,
  MEDIA: 1,
};

export const PULSE_TYPE = {
  NORMAL: 0,
  EVENT: 1,
  ASSESS: 2,
  MULTIPLE_SUBMISSIONS: 3,
  REGISTER: 4,
  CHECK_IN: 5,
};

export const emailRegex = /@(?:[a-z0-9]+\.)+[a-z]+$/i;

export const pulseTypeOptions = [
  { label: 'Register', value: configSite.PULSE_TYPE.REGISTER },
  { label: 'Check in', value: configSite.PULSE_TYPE.CHECK_IN },
  { label: 'Normal', value: configSite.PULSE_TYPE.NORMAL },
  { label: 'Assessment', value: configSite.PULSE_TYPE.ASSESS },
];

export const answerOptions = [
  { label: 'Once', value: ANSWER.ONCE },
  { label: 'Many', value: ANSWER.MANY },
];

export const STATUS = {
  DRAFT: 0,
  WAITING: 1,
  RUNNING: 2,
  CLOSED: 3,
};

export const GU_MAIL_DOMAIN = '@geekup.vn';

export const PULSE_TYPE_LABEL = {
  [PULSE_TYPE.NORMAL]: 'Normal Pulse',
  [PULSE_TYPE.EVENT]: 'Event Pulse',
  [PULSE_TYPE.ASSESS]: 'Assessment Pulse',
  [PULSE_TYPE.MULTIPLE_SUBMISSIONS]: 'Multiple Submissions Pulse',
  [PULSE_TYPE.REGISTER]: 'Register Pulse',
  [PULSE_TYPE.CHECK_IN]: 'Check-in Pulse',
};

export const PULSE_PATH = `${API_URL}/pulses`;